:root {
  --color-white: #ffffff;
  --color-background: #ffffff;
  --color-default: #008FD1;
	--color-primary: #00A0E0;
  --color-secondary: #D81A63;
  --color-others:#2BB2A7;
  --color-dark: #222;
  --transicion: all 0.3s ease;
}

html,body{
  background-color:  var(--color-background);
}

/*Small devices (landscape phones, 576px and up)*/
@media (min-width: 300px) {
  h1 {
    font-size: 1.5rem !important;
  }
}

/*Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) {
  h1 {
    font-size: 2.0rem !important;
  }
}
/*Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
  h1 {
    font-size: 2.0rem !important;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  h1 {
    font-size: 3.5rem !important;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  h1 {
    font-size: 1.2rem;
  }
}

.img-lg{
  width: 240px;
  height: 140px;
}

.img-max-240{
  max-height: 340px;
}

.inputfile{
  opacity: 0;
  width: 100%;
  height: 30px;
  background-color: #333;
  z-index: 100;
}

.bg-gray{
  background-color: #fafafa;
}
.btn-danger{
  background-color: #FF8A15 !important;
  border: none !important;
}

.card-body > .btn-danger, .card-body > .btn-danger:hover{
  background-color: #FF0017 !important;
  border-color: #FF0017 !important;
}

.btn-danger:hover{
  background-color: #FF6A15 !important;
  border: none !important;
}

.espacio{
    margin-left: 10px
}

.color-wizar-up{
    background: #2196f3;
    padding:20px;
}

#progressbar {
  margin-bottom: 3vh;
  overflow: hidden;
  color: rgb(252, 103, 49);
  padding-left: 0px;
  margin-top: 3vh
}

#progressbar li {
  list-style-type: none;
  font-size: 15px;
  width: 25%;
  float: left;
  position: relative;
  font-weight: 400;
  color: rgb(160, 159, 159)
}

#progressbar #step1:before {
  content: "";
  color: rgb(252, 103, 49);
  width: 5px;
  height: 5px;
  margin-left: 0px !important
}

#progressbar #step2:before {
  content: "";
  color: #fff;
  width: 5px;
  height: 5px;
  margin-left: 32%
}

#progressbar #step3:before {
  content: "";
  color: #fff;
  width: 5px;
  height: 5px;
  margin-right: 32%
}

#progressbar #step4:before {
  content: "";
  color: #fff;
  width: 5px;
  height: 5px;
  margin-right: 0px !important
}

#progressbar li:before {
  line-height: 29px;
  display: block;
  font-size: 12px;
  background: #ddd;
  border-radius: 50%;
  margin: auto;
  z-index: -1;
  margin-bottom: 1vh
}

#progressbar li:after {
  content: '';
  height: 2px;
  background: #ddd;
  position: absolute;
  left: 0%;
  right: 0%;
  margin-bottom: 2vh;
  top: 1px;
  z-index: 1
}

.progress-track {
  padding: 0 0%
}

#progressbar li:nth-child(2):after {
  margin-right: auto
}

#progressbar li:nth-child(1):after {
  margin: auto
}

#progressbar li:nth-child(3):after {
  float: left;
  width: 68%
}

#progressbar li:nth-child(4):after {
  margin-left: auto;
  width: 132%
}

#progressbar li.active {
  color: black
}

#progressbar li.active:before,
#progressbar li.active:after {
  background: rgb(49, 52, 252)
}


.toasts-container {
  position: fixed;
  overflow: hidden;
  z-index: 999999999999;
  max-height: calc(30vh - 10px) !important;
  text-align: right;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
